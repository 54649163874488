<template>
  <div id="print_area">

    <div id="print_target">
      <div id="print_content" class="receipt" ref="print_content">
        <img src="@/statics/img/居家-收據-01.png" alt="">

        <div class="receipt_title table_value left">
          {{ receiptInfo.title || receiptInfo.user_name }}
        </div>
        <div class="receipt_username table_value left">
          {{ receiptInfo.user_name }}
        </div>
        <div class="receipt_price table_value left">
          $ {{ receiptInfo.total }}
        </div>
        <div class="receipt_content table_value left">
          {{ receiptInfo.content }}
        </div>

        <div class="receipt_feeno table_value right">
          {{ receiptInfo.fee_no }}
        </div>
        <div class="receipt_userno table_value right">
          {{ receiptInfo.user_no }}
        </div>
        <div class="receipt_paytime table_value right">
          {{ receiptInfo.pay_time }}
        </div>
        <div class="receipt_paytype table_value right">
          {{ receiptInfo.pay_type }}
        </div>
      </div>
    </div>

    <div class="print_btn txt-center noprint">
      <button class="btn main_font" @click="printTarget">下載</button>
    </div>

  </div>
</template>

<script>
import html2pdf from 'html2pdf.js';

export default {
  name: 'attendareceiptPDFncePDF',
  data() {
    return {
      receiptInfo: {},
    };
  },
  created() {
    this.receiptInfo = this.$cookies.get('receiptInfo');
  },
  methods: {
    printTarget() {
      // window.print();

      window.scrollTo(0, 0);
      const opt = {
        margin: 0,
        filename: '收據',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'landscape',
          compress: false,
        },
      };
      html2pdf().set(opt).from(this.$refs.print_content).save();
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/earlyaccess/cwtexkai.css");

header, footer {
  display: none !important;
}
body {
  padding-top: 0;
  font-family: '標楷體', 'DFKai-SB', 'BiauKai', 'STKaiti', 'cwTeXKai', serif !important;
  background-color: rgba(gray, .1);
}
/** 包含此class的對象將從預覽列印中移除 */
@media print {
  .noprint {
    display: none;
  }
}
/** 消除預覽列印時的多餘邊界 */
@page {
  margin: 0cm;
}
</style>
